import MiniDrawer from "./drawer/Drawer";
import React, {useEffect} from 'react'
import {Outlet} from "react-router";
import {useAuthContext} from "../../contexts/Auth";
import {Backdrop, CircularProgress} from "@mui/material";
import {useSettingsContext} from "../../contexts/settings/SettingsContext";

const Layout = () => {
    const {isLoggedIn, isInit: isAuthInit, navToLogin} = useAuthContext();
    const {isInit: isSettingInit} = useSettingsContext()
    const [_isDrawerOpen, setIsDrawerOpen] = React.useState<boolean>(true);

    useEffect(() => {
        if (!isLoggedIn && isAuthInit) {
            navToLogin()
        }
    }, [isLoggedIn, isAuthInit, navToLogin])

    const content = isLoggedIn && isSettingInit ? <Outlet/> : <Backdrop
        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={_isDrawerOpen}
        onClick={() => setIsDrawerOpen(false)}
    >
        <CircularProgress color="inherit"/>
    </Backdrop>
    return <MiniDrawer withDrawer={false}>{content}</MiniDrawer>
}

export default Layout;