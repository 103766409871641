import { useLocation } from "react-router";
import { Box, Stack, Typography } from "@mui/material";
// import DocumentFilter from "../../components/document/filter/DocumentFilter";
import Chat from "../../components/chat/Chat";
import SchemaFilter from "./SchemaFilter";

const DataChat = () => {
  const { state } = useLocation();
  // const documentIds = state?.documentIds as string[] | undefined
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Stack
        direction="column"
        sx={{ width: { sm: 500, md: 800 } }}
        spacing={2}
      >
        <SchemaFilter />
        <Chat />
      </Stack>
    </Box>
  );
};
export default DataChat;
