import React, {createContext, JSX, useCallback, useContext, useEffect, useState} from 'react';
import {CognitoJwtVerifier} from 'aws-jwt-verify'
import {useNavigate} from "react-router";
import {CognitoIdTokenPayload} from "aws-jwt-verify/jwt-model";
import {backendClient} from "../api/clients";

const AUTH_ENDPOINT = process.env.REACT_APP_AUTH_ENDPOINT;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID as string;
const USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID as string;
const ORIGIN_URL = window.location.origin

interface Base {
    user: CognitoIdTokenPayload | null;
    isInit: boolean;
}

interface IAuthContext extends Base {
    isLoggedIn: boolean;
    login: (idToken: string, accessToken: string) => Promise<boolean>;
    logout: () => void;
    resetAndReLogIn: () => void;
    navToLogin: () => void;

}

interface AuthState extends Base {
    idToken: string | null;
}


const authContext = createContext<IAuthContext>({
    user: null,
    isInit: false,
    isLoggedIn: false,
    login: async () => false,
    logout: () => null,
    resetAndReLogIn: () => null,
    navToLogin: () => null,
});

const useAuthContext = () => useContext(authContext);

const accessVerifier = CognitoJwtVerifier.create({
    userPoolId: USER_POOL_ID,
    tokenUse: "access",
    clientId: CLIENT_ID,
});

const idVerifier = CognitoJwtVerifier.create({
    userPoolId: USER_POOL_ID,
    tokenUse: "id",
    clientId: CLIENT_ID,
});

const AuthContext = ({children}: { children: JSX.Element }) => {
    const [{idToken, user, isInit}, setState] = useState<AuthState>({
        idToken: localStorage.getItem('idToken'),
        user: null,
        isInit: false,
    });
    const navigate = useNavigate();

    const loginUrl = `${AUTH_ENDPOINT}/login?response_type=token&client_id=${CLIENT_ID}&scope=email+openid+phone&redirect_uri=${ORIGIN_URL}/callback/login`
    const logoutUrl = `${AUTH_ENDPOINT}/logout?client_id=${CLIENT_ID}&response_type=token&redirect_uri=${ORIGIN_URL}/callback/login`


    useEffect(() => {
        const id = backendClient.interceptors.response.use((response) => response, (error) => {
            if (error?.response?.status === 401 || error?.response?.status === 403) {
                navToLogin()
            }
            return Promise.reject(error);
        })
        return () => {
            backendClient.interceptors.response.eject(id);
        }
    }, [])

    const onLoginSuccess = useCallback((idToken: string, accessToken: string) => {
        backendClient.defaults.headers.common.Authorization = `Bearer ${idToken}`;
        setState((prev) => ({...prev, idToken, isInit: true}))
        navigate('/chat')
    }, [navigate])

    const login = useCallback(async (idToken: string, accessToken: string) => {
        try {
            await accessVerifier.verify(accessToken);
            localStorage.setItem('idToken', idToken);
            onLoginSuccess(idToken, accessToken)
            return true
        } catch (e) {
            console.error(e)
            return false
        } finally {
            setState((prev) => ({...prev, isInit: true}))
        }
    }, [onLoginSuccess]);

    const resetAuth = useCallback(() => {
        localStorage.removeItem('idToken');
        backendClient.defaults.headers.common.Authorization = '';
        setState((prev) => ({...prev, idToken: null}))
    }, [])

    const logout = useCallback(() => {
        resetAuth()
        window.location.href = logoutUrl
    }, [resetAuth, logoutUrl])

    const navToLogin = useCallback(() => {
        window.location.href = loginUrl
    }, [loginUrl])

    const resetAndReLogIn = useCallback(() => {
        resetAuth()
        window.location.href = loginUrl
    }, [resetAuth, loginUrl])

    useEffect(() => {
        backendClient.defaults.headers.common.Authorization = `Bearer ${idToken}`;
        if (idToken) {
            idVerifier.verify(idToken).then((user) => {
                setState((prev) => ({...prev, user, isInit: true}))
            }).catch(() => {
                resetAndReLogIn()
            })
        } else {
            resetAndReLogIn()
        }
    }, [idToken])

    return (
        <authContext.Provider
            value={{
                user: user,
                isLoggedIn: idToken !== null,
                isInit: isInit,
                navToLogin: navToLogin,
                resetAndReLogIn: resetAndReLogIn,
                login: login,
                logout: logout,
            }}
        >
            {children}
        </authContext.Provider>
    );
};

export {AuthContext, useAuthContext};
