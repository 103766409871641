import { Autocomplete, Grid, TextField } from "@mui/material";
import { useSettingsContext } from "../../contexts/settings/SettingsContext";
import { useState } from "react";

interface SchemaFilterProps {
  selectedSchemas?: string[];
}

const SchemaFilter = ({ selectedSchemas }: SchemaFilterProps) => {
  const { schemas } = useSettingsContext();
  const [_schemas, setSchemas] = useState(selectedSchemas ?? [schemas[0]]);

  return (
    <Grid container columns={12} spacing={2}>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          options={schemas}
          value={_schemas}
          onChange={(event, values) => {
            setSchemas(values);
          }}
          renderInput={(params) => (
            <TextField {...params} variant="standard" label="Projects" />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default SchemaFilter;
