import React, {createContext, JSX, useContext, useEffect, useState} from 'react';
import {IDocumentType} from "../../types/api";
import {useAuthContext} from "../Auth";
import {backendClient} from "../../api/clients";

export interface SettingsState {
    schemas: string[];
    isInit: boolean;
}

interface ISettingsContext extends SettingsState {
}


const settingsContext = createContext<ISettingsContext>({
    schemas: [],
    isInit: false
});

const useSettingsContext = () => useContext(settingsContext);


const SettingsContext = ({children}: { children: JSX.Element }) => {
    const {isInit: isAuthInit} = useAuthContext();
    const [{schemas, isInit}, setState] = useState<SettingsState>({
        schemas: [], isInit: false
    });

    useEffect(() => {
        const fetch = async () => {
            const {data} = await backendClient.get('/api/settings')
            setState({
                schemas: data.result.schemas as string[],
                isInit: true
            })
        }
        if (isAuthInit && !isInit) {
            fetch().catch(console.error)
        }
    }, [isInit, isAuthInit])

    return (
        <settingsContext.Provider
            value={{
                schemas,
                isInit
            }}
        >
            {children}
        </settingsContext.Provider>
    );
};

export {SettingsContext, useSettingsContext};
