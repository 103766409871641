import {AppBar} from "./styled";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Avatar, Button, Container, Menu, MenuItem, Tooltip} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import {useAuthContext} from "../../../../contexts/Auth";
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import {pages} from "../pages";
import {useNavigate} from "react-router";

const MyAppBar = () => {
    const {logout, user} = useAuthContext();
    const settings: { title: string, onClick: () => void }[] = [{title: 'Logout', onClick: logout}];
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const navigate = useNavigate()

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    return <AppBar>
        <Container maxWidth="xl">
            <Toolbar disableGutters>
                <LocalLibraryIcon sx={{display: {xs: 'none', md: 'flex'}, mr: 1}}/>
                <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    href="/"
                    sx={{
                        mr: 2,
                        display: {xs: 'none', md: 'flex'},
                        fontWeight: 700,
                        letterSpacing: '.3rem',
                        color: 'inherit',
                        textDecoration: 'none',
                    }}
                >
                    Data Bud
                </Typography>

                <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: {xs: 'block', md: 'none'},
                        }}
                    >
                        {pages.map((page) => (
                            <MenuItem key={page.title} onClick={() => {
                                navigate(page.path)
                                handleCloseNavMenu()
                            }}>
                                <Typography textAlign="center">{page.title}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
                <LocalLibraryIcon sx={{display: {xs: 'flex', md: 'none'}, mr: 1}}/>
                <Typography
                    variant="h5"
                    noWrap
                    component="a"
                    href=""
                    sx={{
                        mr: 2,
                        display: {xs: 'flex', md: 'none'},
                        flexGrow: 1,
                        fontWeight: 700,
                        letterSpacing: '.3rem',
                        color: 'inherit',
                        textDecoration: 'none',
                    }}
                >
                    Dat Bud
                </Typography>
                <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                    {pages.map((page) => (
                        <Button
                            key={page.title}
                            onClick={() => {
                                navigate(page.path)
                                handleCloseNavMenu()
                            }}
                            sx={{my: 2, color: 'white', display: 'block'}}
                        >
                            {page.title}
                        </Button>
                    ))}
                </Box>

                <Box sx={{flexGrow: 0}}>
                    <Tooltip title="Open settings">
                        <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                            {/* @ts-ignore*/}
                            <Avatar>{user?.email?.[0]?.toUpperCase()}</Avatar>
                        </IconButton>
                    </Tooltip>
                    <Menu
                        sx={{mt: '45px'}}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        {settings.map((setting) => (
                            <MenuItem key={setting.title} onClick={() => {
                                setting.onClick()
                                handleCloseUserMenu()
                            }}>
                                <Typography textAlign="center">{setting.title}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
            </Toolbar>
        </Container>
        {/*<Toolbar>*/}
        {/*    <Box display="flex" justifyContent="space-between" width="100%">*/}
        {/*        <Typography variant="h6" noWrap component="div">*/}
        {/*            Pilates Hub*/}
        {/*        </Typography>*/}
        {/*        <Tooltip title={"Log Out"}>*/}
        {/*            <Box>*/}
        {/*                <IconButton onClick={logout}>*/}
        {/*                    <LogoutIcon/>*/}
        {/*                </IconButton>*/}
        {/*            </Box>*/}
        {/*        </Tooltip>*/}
        {/*    </Box>*/}
        {/*</Toolbar>*/}
    </AppBar>
}

export default MyAppBar;