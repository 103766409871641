import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  MessageModel,
} from "@chatscope/chat-ui-kit-react";
import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { backendClient } from "../../api/clients";

const BOT_NAME = "Hubby";

const DataChat = () => {
  const { state } = useLocation();
  const [_isWaiting, setIsWaiting] = useState<boolean>(false);
  const [_sessionId, setSessionId] = useState<string | undefined>(undefined);
  const [_messageHistory, setMessageHistory] = useState<MessageModel[]>([
    {
      message:
        "Hello, I am here to help you query data from your internal projects. How can I help you?",
      sentTime: "just now",
      sender: BOT_NAME,
      direction: "incoming",
      position: "single",
    },
  ]);

  const handleSend = async (innerHtml: string, textContent: string) => {
    setIsWaiting(true);
    try {
      setMessageHistory((prev) => [
        ...prev,
        {
          message: textContent,
          sentTime: "just now",
          sender: "Me",
          direction: "outgoing",
          position: "single",
          type: "html",
        },
      ]);
      let url = `/api/chat?prompt=${textContent}`;
      if (_sessionId) {
        url = `${url}&sessionId=${_sessionId}`;
      }
      const { data } = await backendClient.get(url);
      setSessionId(data.result.sessionId);
      const response = data.result.content;
      setMessageHistory((prev) => [
        ...prev,
        {
          message: response,
          sentTime: "just now",
          sender: BOT_NAME,
          direction: "incoming",
          position: "single",
        },
      ]);
    } catch (e) {
      console.error(e);
      alert("Error sending message");
    } finally {
      setIsWaiting(false);
    }
  };

  return (
    <Box position="relative" height={{ sm: "300px", md: "500px" }}>
      <MainContainer>
        <ChatContainer>
          <MessageList>
            {_messageHistory.map((message, idx) => (
              <Message key={idx} model={message} />
            ))}
            {_isWaiting ? (
              <Message
                model={{
                  message: "...",
                  sentTime: "just now",
                  sender: BOT_NAME,
                  direction: "incoming",
                  position: "single",
                }}
              />
            ) : null}
          </MessageList>
          <MessageInput
            placeholder="Type message here"
            attachButton={false}
            onSend={handleSend}
          />
        </ChatContainer>
      </MainContainer>
    </Box>
  );
};

export default DataChat;
