import * as React from "react";
import * as ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import {backendClient} from "./api/clients";

if (process.env.REACT_APP_BACKEND_ENDPOINT) {
    backendClient.defaults.baseURL = process.env.REACT_APP_BACKEND_ENDPOINT
}

// @ts-ignore
ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);
