import React, {useEffect} from 'react';
import {useAuthContext} from "../contexts/Auth";
import Typography from "@mui/material/Typography";

const Login = () => {

    const search = window.location.hash.substring(1);
    const params = new URLSearchParams(search);
    const idToken = params.get('id_token') as string
    const accessToken = params.get('access_token') as string
    localStorage.setItem("idToken", idToken);
    const {login, resetAndReLogIn} = useAuthContext()

    useEffect(() => {
        (async () => {
            if (accessToken) {
                const success = await login(idToken, accessToken)
                if (!success) {
                    alert('Login failed')
                }
            } else {
                resetAndReLogIn()
            }
        })().catch(console.error)
    }, [login, idToken, accessToken, resetAndReLogIn])

    return <Typography>Logging in...</Typography>
}

export default Login;