import React from 'react';
import './App.css';
import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";
import {AuthContext} from "./contexts/Auth";
import {Outlet} from "react-router";
import Layout from "./components/layout/Layout";
import Login from "./pages/Login";
import {useTheme} from "@mui/material/styles";
import {ThemeProvider} from "@emotion/react";
import {QueryClient, QueryClientProvider} from "react-query";
import DataChat from "./pages/chat/Chat";
import {SettingsContext} from "./contexts/settings/SettingsContext";

const router = createBrowserRouter([
        {
            path: "/",
            element: <AuthContext><SettingsContext><Outlet/></SettingsContext></AuthContext>,
            children: [{
                path: "/",
                element: <Layout/>,
                children: [
                    {
                        path: "/",
                        element: <Navigate to="/chat" replace/>
                    },
                    {
                        path: "chat",
                        element: <DataChat/>
                    }
                ]
            }, {
                path: "callback/login",
                element: <Login/>
            }]
        }
    ])
;
const queryClient = new QueryClient();

function App() {
    const theme = useTheme();
    return <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
            <RouterProvider router={router}/>
        </QueryClientProvider>
    </ThemeProvider>
}

export default App;
